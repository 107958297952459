// Core
@import "animoSCSS/custom/functions";
@import "animoSCSS/bootstrap/functions";


@import "animoSCSS/bootstrap/variables";
@import '../../assets/css/welcome.css';
@import '../../assets/css/xray.css';
@import '../../assets/css/heatmap.css';
@import '../../assets/css/rt-viewer.css';
@import '../../assets/css/rta-vertical.css';
@import '../../assets/css/Benchmarking.css';
@import '../../assets/css/page-config.css';
@import '../../assets/css/performance-kpi.css';
@import '../../assets/css/drilling-multi.css';
@import '../../assets/css/morning-call.css';
@import '../../assets/css/system-support.css';
@import '../../views/pages/animoEngagement/weeklyUpdates.css';
@import '../../views/pages/multiWellTools/offsetReview/offsetReview.css';
@import '../../views/pages/singleWell/drillingAnalytics/TDA/tda.css';
@import '../../views/pages/singleWell/drillingAnalytics/directional/components/directionalDashboard.css';
@import 'animoSCSS/custom/variables';
@import 'animoSCSS/custom/mixins';
@import 'animoSCSS/bootstrap/mixins';
@import '../../assets/css/highcharts/gui.css';
@import '../../assets/css/data-grid.css';
// Bootstrap components
@import "animoSCSS/bootstrap/root";
@import "animoSCSS/bootstrap/reboot";
@import "animoSCSS/bootstrap/type";
@import "animoSCSS/bootstrap/images";
@import "animoSCSS/bootstrap/code";
@import "animoSCSS/bootstrap/grid";
@import "animoSCSS/bootstrap/tables";
@import "animoSCSS/bootstrap/forms";
@import "animoSCSS/bootstrap/buttons";
@import "animoSCSS/bootstrap/transitions";
@import "animoSCSS/bootstrap/dropdown";
@import "animoSCSS/bootstrap/button-group";
@import "animoSCSS/bootstrap/input-group";
@import "animoSCSS/bootstrap/custom-forms";
@import "animoSCSS/bootstrap/nav";
@import "animoSCSS/bootstrap/navbar";
@import "animoSCSS/bootstrap/card";
@import "animoSCSS/bootstrap/breadcrumb";
@import "animoSCSS/bootstrap/pagination";
@import "animoSCSS/bootstrap/badge";
@import "animoSCSS/bootstrap/jumbotron";
@import "animoSCSS/bootstrap/alert";
@import "animoSCSS/bootstrap/progress";
@import "animoSCSS/bootstrap/media";
@import "animoSCSS/bootstrap/list-group";
@import "animoSCSS/bootstrap/close";
@import "animoSCSS/bootstrap/modal";
@import "animoSCSS/bootstrap/toast";
@import "animoSCSS/bootstrap/toastContainer";
@import "animoSCSS/bootstrap/tooltip";
@import "animoSCSS/bootstrap/popover";
@import "animoSCSS/bootstrap/carousel";
@import "animoSCSS/bootstrap/utilities";
@import "animoSCSS/bootstrap/print";

// Custom components
@import "animoSCSS/custom/reboot.scss";
@import "animoSCSS/custom/global.scss";
@import "animoSCSS/custom/utilities.scss";
@import "animoSCSS/custom/alerts.scss";
@import "animoSCSS/custom/avatars.scss";
@import "animoSCSS/custom/badge.scss";
@import "animoSCSS/custom/buttons.scss";
@import "animoSCSS/custom/close.scss";
@import "animoSCSS/custom/custom-forms.scss";
@import "animoSCSS/custom/dropdown.scss";
@import "animoSCSS/custom/footer.scss";
@import "animoSCSS/custom/forms.scss";
@import "animoSCSS/custom/grid.scss";
@import "animoSCSS/custom/icons.scss";
@import "animoSCSS/custom/images.scss";
@import "animoSCSS/custom/input-group.scss";
@import "animoSCSS/custom/info-areas.scss";
@import "animoSCSS/custom/modal.scss";
@import "animoSCSS/custom/nav.scss";
@import "animoSCSS/custom/navbar.scss";
@import "animoSCSS/custom/pagination.scss";
@import "animoSCSS/custom/tabs.scss";
@import "animoSCSS/custom/pills.scss";
@import "animoSCSS/custom/popover.scss";
@import "animoSCSS/custom/progress.scss";
@import "animoSCSS/custom/type.scss";
@import "animoSCSS/custom/tables";
@import "animoSCSS/custom/checkboxes-radio";
@import "animoSCSS/custom/fixed-plugin";
@import "animoSCSS/custom/sidebar-and-main-panel.scss";
@import "animoSCSS/custom/misc.scss";
@import "animoSCSS/custom/rtl.scss";
@import "animoSCSS/custom/timeline.scss";
@import "animoSCSS/custom/map.scss";

// Vendor / Plugins
@import "animoSCSS/custom/vendor/plugin-datetimepicker.scss";
@import "animoSCSS/custom/vendor/plugin-nouislider.scss";
@import "animoSCSS/custom/vendor/plugin-fullcalendar.scss";
@import "animoSCSS/custom/vendor/plugin-sweetalert2.scss";
@import "animoSCSS/custom/vendor/plugin-bootstrap-switch.scss";
@import "animoSCSS/custom/vendor/plugin-bootstrap-select.scss";
@import "animoSCSS/custom/vendor/plugin-jasny-fileupload.scss";
@import "animoSCSS/custom/vendor/plugin-tagsinput.scss";
@import "animoSCSS/custom/vendor/plugin-perfect-scrollbar.scss";
@import "animoSCSS/custom/vendor/plugin-jquery.jvectormap.scss";
@import "animoSCSS/custom/vendor/plugin-datatables.net.scss";
@import "animoSCSS/custom/vendor/plugin-animate-bootstrap-notify.scss";
@import "animoSCSS/custom/vendor/plugin-card-wizard.scss";

// example pages and sections
@import "animoSCSS/custom/example-pages.scss";

// light mode
@import "animoSCSS/bootstrap/white-content.scss";

// Cards
@import "animoSCSS/custom/card";
@import "animoSCSS/custom/cards/card-chart-perfdash";
@import "animoSCSS/custom/cards/card-chart";
@import "animoSCSS/custom/cards/card-map";
@import "animoSCSS/custom/cards/card-user";
@import "animoSCSS/custom/cards/card-task";
@import "animoSCSS/custom/cards/card-animation-on-hover";
@import "animoSCSS/custom/cards/card-collapse";
@import "animoSCSS/custom/cards/card-contributions";
@import "animoSCSS/custom/cards/card-lock";
@import "animoSCSS/custom/cards/card-pricing";
@import "animoSCSS/custom/cards/card-profile";
@import "animoSCSS/custom/cards/card-plain";
@import "animoSCSS/custom/cards/card-register";
@import "animoSCSS/custom/cards/card-stats";
@import "animoSCSS/custom/cards/card-subcategories";
@import "animoSCSS/custom/cards/card-testimonials";
@import "animoSCSS/custom/cards/card-wizard";

// React Differences
@import "animoSCSS/react/react-differences";