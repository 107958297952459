.card-tasks .card-header .title {
  margin-right: 24px;
}

.cardHolder {
  position: relative;
}

.hoverCard {
  position: absolute;
  left: 310px;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: #3a3c3dde !important;
  border-radius: 5px;
  z-index: 1000000;

}

.chartLegends {
  padding: 0 15px;
  margin-right: calc(2%);
}

.legendColor {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.legendColor::before {
  content: "e";
  color: #f0f8ff00;
}

.sideMemo {
  font-size: 30px;

  width: 30px;
  padding: 0 2px;
  background: #d3d3d3a8;
  margin-left: 5px;
}

.memoContainer {
  height: 6.6%;
  display: flex;
  align-items: center;
}

.memoItem {
  font-size: 12px;
  border: 1px solid #9c9c9c;
  width: 27px;
  border-radius: 67px;
  line-height: 25px;
  background: #565656;
}

.popOverBody {
  color: black;
}

.depthDayScale {
  position: relative;
  /* background-color: white; */
  border-left: 2px solid #6e6e6e;
  display: inline-block;
  width: 130px;
  overflow: hidden;
  z-index: 10;
  margin-left: 7px;
  margin-right: 7px;
  font-family: "Poppins", sans-serif;
}

.scaleItem {
  position: relative;
  background-color: white;
  border-top: 1px dashed #989a9c;
  font-family: monospace;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  justify-content: center;
  font-size: 11px;
  line-height: 11px;
  padding-left: 4px;
  padding-right: 4px;
  font-family: "Poppins", sans-serif;
}

.scaleMarker {
  border-top: 1px dashed #dddddd;
  width: 25%;
  margin-left: 75%;

}

.scaleItem:first-child {
  border-top: 0px dashed #989a9c;
}

.scaleItem:last-child {
  padding-bottom: 0;
  border-left: none;
  background-color: white;

}

.scaleItem:last-child .depthTimeText {
  margin-top: -15px;
}

.scaleDarkMode {
  background-color: #2e2e2e !important;
  border-top: 1px dashed #989a9c !important;
}

.scaleDepthText {
  position: absolute;
  top: 20%;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 600;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.onBottom {
  color: #2c5c69;
}

.onBottomDark {
  color: #00b3cf !important;
}

.offBottom {
  color: #a45200
}

.offBottomDark {
  color: #ffd15c
}

.scaleDateText {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 10%;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: black
}

.scaleDateTextDark {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 10%;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #e3e3e3
}

.dateText {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 600;
  margin-top: -25px;
}

.scaleItem:first-child .dateText {
  margin-top: 0;
}

.commentMemoSection {
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;

}

.commentMemoItem {
  border-bottom: 1px solid #d3d2d2;
  display: flex;
  line-height: 16px;
}

.commentMemoItem:last-child {
  border: none;
}

.commentTime {
  position: relative;
  width: 35px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 16px;
  color: rgb(59, 59, 59);
  padding-right: 2px;
}

.commentTime:after {
  content: " ";
  position: absolute;
  height: calc(100% - 15px);
  left: 45%;
  top: 15px;
  /* border-top: 1px solid #50000f; */
  background: #c7c7c7;
  width: 1px;
}

.commentMemoItem:last-child .commentTime:after {

  width: 0px;

}

.commentArea {
  border-bottom: 1px solid #d3d2d2;
  padding-left: 2px;
  margin-bottom: 5px;
  width: calc(100% - 40px);
}

.commentMemoItem:last-child .commentArea {
  border: none;
}

.noshow {
  visibility: hidden;
}

/* this is the bottom part of the the traces */
.bottomSectionRT {
  position: fixed;
  bottom: 0;
  width: calc(100% - 140px);
  left: 120px;
  background: #3e3e3eee;
  height: 30px;
  z-index: 1003;
  transition: .5s ease-in-out;
  border-top: 2px solid #a0a0a0;
  box-shadow: 0 0 9px 0px #333;
}

.hover:hover {
  height: 50px;
  z-index: 1003;
  transition: .5s ease-in-out;
  border-top: 2px solid #a0a0a0;
  box-shadow: 0 0 18px 0px #00e29f;
}

.slideOutRT {
  height: 85%;
  min-height: 600px;
}

.titleBar {
  background-color: #3c3c3c;
  line-height: 40px;
}

.bottomParamDetailText {
  align-self: center;
  width: 100%;
  color: white;
  font-size: 0.7625rem;
  line-height: 1.4em;
  font-weight: 600 !important
}

.bottomSectionRT .mainRTChartSection {
  display: flex;
  height: calc(100% - 60px);
  overflow: hidden;
}

.mainRTChartSection .controls,
.mainRTChartSection .info {
  width: 20%;
  border: 1px solid #5a5a5a;
  padding: 0 15px;
}

.mainRTChartSection .mainChart {
  width: 60%;
  border: 1px solid #5a5a5a;
  border-right: none;
  border-left: none;
}

.sideArea {
  display: flex;
  justify-content: space-between;
  max-height: 100vh;
  padding: 10px;

}

.chartSideNav {
  width: 290px;
  min-width: 290px;
  display: flex;
}

.mainCharts {

  position: relative;
  width: calc(100% - 10px);
}


.mainChart::-webkit-scrollbar {
  height: 0;
  width: 10px;
}

.mainChart::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 10px;
}

.mainChart::-webkit-scrollbar-track-piece,
.mainChart::-webkit-scrollbar-track {
  background-color: transparent;
  height: 8px;
}

.sideCardContainer {
  width: 100px;
}

.sideCard .topRow {
  display: flex;
  justify-content: space-between;
}

.charts {
  display: flex;
}

.sideCards {
  width: 110px;
}

.chartInfo {
  display: flex;
  width: 100%;
  text-align: center
}

.seperator {
  margin: 5px 0;
}


.infoItem {
  width: 50%;
  margin: 15px auto;
  background: #2d2d2d;
  padding: 5px;
  border-radius: 5px;
  text-align: center
}

.controlItem {
  margin-bottom: 15px;
}

.infoItem.buttons {
  display: flex;
  background: none;
}

.infoItem.buttons>a {
  padding: 0;
  margin: auto;
}

.titleBar>p {
  margin: 0;
}



.commentsList {
  position: absolute;
  top: 0;
}

.commentWrapper {
  position: relative;
}

.commentItem {
  position: absolute;
  width: 200px;
  background: #929292;
  min-height: 50px;
  overflow: hidden;
  border: 1px solid #cacaca;
  padding: 2px;
}

.displayNone {
  display: none;
}

.commentContent {
  padding-top: 20px;
  overflow-y: auto;
  max-height: 100px;
  font-size: 10px;
}

.commentContent::-webkit-scrollbar {
  width: 5px;
}

.commentContent::-webkit-scrollbar-track {
  background: #ddd;
}

.commentContent::-webkit-scrollbar-thumb {
  background: rgba(172, 172, 172, 0.5);
}

.closeBtn {
  position: absolute;
  right: 5px;
  cursor: pointer;
  padding: 2px 5px;
  background: #f9f9f914;
}

.closeBtn:hover {
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.2);
}

.rangeDropdown {
  border: 1px solid #23818b;
  background: #198691;
  margin-left: 5px;
  font-size: 12px;
  height: 30px;
  vertical-align: -webkit-baseline-middle;
  margin-top: 6px;
  border-radius: 5px;
  color: white;
}

.BottomRTViewer {
  display: flex;
  width: 100%;
  height: fit-content
}

.RTChartArea {
  width: 100%;
  display: flex;

}

.RTMainNavigation {
  position: relative;
  width: 110px;
  margin-right: 10px;
  border-right: 2px solid #6e6e6e;
  height: fit-content;
}

.RTMainXRange {
  /* margin-top:20px; */
  position: absolute;
  z-index: 20;
  margin-left: 96px;
  width: 200px;
  height: fit-content;
}

.RTMainChartOverlay {
  position: absolute;
  z-index: 30;
  /* width: calc(100% - 250px); */
}

.RTMainChartArea {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;

  background-color: #6e6e6e;
  position: relative;
  width: calc(100% - 250px);
}

.RTAddChartColumn {
  display: flex;
  background-color: #b3b3b3;
  position: absolute;
  width: 21px;
  right: -15px;
  top: 85%;
  height: 50px;
  margin-left: -2px;
  color: #2c5c69;
  font-size: 25px;
  border-right: 3px solid #2c5c69;
  border-left: 2px solid #00e29f;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer
}

.RTAddChartColumn:hover {
  background-color: #00e29f;
  color: white;
  box-shadow: 0 0 5px 0px #2c5c69;
}

.RTAddChartButtonHz {
  display: flex;
  background-color: #b3b3b3;
  position: absolute;
  width: 50px;
  left: 112px;
  top: -4px;
  height: 21px;
  margin-left: -2px;
  color: #2c5c69;
  font-size: 25px;
  border-top: 3px solid #2c5c69;
  border-bottom: 2px solid #00e29f;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer
}

.RTAddChartButtonHz:hover {
  background-color: #00e29f;
  color: white;
  box-shadow: 0 0 5px 0px #2c5c69;
}

.HeatMapAddChartButtonHz {
  display: flex;
  background-color: #b3b3b3;
  position: absolute;
  width: 50px;
  right: 0%;
  bottom: -24px;
  height: 21px;
  margin-left: -2px;
  color: #2c5c69;
  font-size: 25px;
  border-bottom: 3px solid #2c5c69;
  border-top: 2px solid #00e29f;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer
}

.HeatMapAddChartButtonHz:hover {
  background-color: #00e29f;
  color: white;
  box-shadow: 0 0 5px 0px #2c5c69;
}

.RTAddChartRow {
  display: flex;
  background-color: #b3b3b3;
  position: absolute;
  width: 50px;
  left: 50%;
  margin-left: auto;
  margin-right: auto;
  height: 21px;
  transform: translate(-50%);
  color: #2c5c69;
  font-size: 20px;
  border-bottom: 3px solid #2c5c69;
  border-top: 2px solid #00e29f;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer
}

.RTAddChartRow:hover {
  background-color: #00e29f;
  color: white;
  box-shadow: 0 0 5px 0px #2c5c69;
}

.RTControlToolbox {
  position: relative;
  padding-top: 20px;
  padding-left: 5px;
  border-top: 2px solid #6e6e6e;
  border-right: 2px solid #6e6e6e;
  width: 264px;
  /* margin-right:7px */
}

.RTControlToolbox .toolboxRow {
  display: flex;
  width: 100%;
  margin: auto;
}

.RTMainTraceArea {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  text-align: center;
  width: calc(100% - 250px);

}

.traceControlsHoverRange {
  position: relative;
  z-index: 0;
  margin-top: -20px;
  padding-top: 20px;
  height: 40px
}

.traceControls {
  cursor: grab;
  position: relative;
  border: 1px solid #2c5c6900;
  z-index: 8;
  transition: all .3s ease-in-out;
}

.traceControls:hover {
  background: #2c5c6936;
  border-radius: 5px;
  padding: -5px;
  border: 1px solid #2c5c6900;
  box-shadow: 0 0 15px #b6b6b6;
}



.traceCardEdit {
  opacity: 0;
  position: absolute;
  top: 6px;
  left: 2px;
  width: 20px;
  color: transparent;

  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  transition: opacity 0.3s linear;
}

.traceControls:hover .traceCardEdit {
  cursor: pointer;
  opacity: 1;
  backdrop-filter: blur(5px);
  z-index: 10;
  color: #2c5c69;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  transition: opacity 0.3s linear;
}

.traceControls:hover .traceCardEdit:hover {
  text-shadow: 0 0 3px #00e29f;
  transform: scale(1.3);
}
.traceCardMin {
  position: absolute;
  text-align: left;
  opacity: 100;
  color: black;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  transition: opacity 0.3s linear;
  font-size: 14px;
  margin-right: auto;
  top: 6px;
  left:2px;
}

.traceControls:hover .traceCardMin {
  position: absolute;
  text-align: left;
  float: left;
  
  opacity: 0;
  color: transparent;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  transition: opacity 0.3s linear;
}
.traceCardMax {
  position: absolute;
  text-align: right;
  opacity: 100;
  color: black;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  transition: opacity 0.3s linear;
  font-size: 14px;
  margin-left: auto;
  top: 6px;
  right:2px;
}

.traceControls:hover .traceCardMax {
  position: absolute;
  text-align: right;
  float: right;
  
  opacity: 0;
  color: transparent;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  transition: opacity 0.3s linear;
}
.traceCardDelete {
  position: absolute;
  text-align: right;
  opacity: 0;
  color: transparent;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  transition: opacity 0.3s linear;
}

.traceControls:hover .traceCardDelete {
  float: right;
  top: 6px;
  z-index: 10;
  margin-left: auto;
  right: 2px;
  cursor: pointer;
  color: red;
  backdrop-filter: blur(5px);
  display: flex;
  opacity: 1;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  transition: opacity 0.2s linear;
}

.traceControls:hover .traceCardDelete:hover {
  text-shadow: 0 0 3px #ff0000;
  transform: scale(1.3);
}

.traceCardAdd {
  z-index: 10;
  display: none;
}

.traceControls:hover .traceCardAdd {
  position: absolute;
  text-align: right;
  float: right;
  top: 25px;
  right: 10px;
  margin-left: auto;
  cursor: pointer;
  color: #2c5c69;
  display: flex;
}

.traceControls:hover .traceCardAdd:hover {
  text-shadow: 0 0 3px #00e29f;
  transform: scale(1.3);
}

.traceCardHzContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  padding-left: 5px;
  /* margin-left: -20px; */
  /* background: #00e29f; */
  z-index: 1100;
  transition: all .3s ease-in-out;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  transition: opacity 0.3s linear;

}

.traceCardHzContainer:hover {
  background: #2c5c6936;
  border-radius: 5px;
  padding: -5px;
}

.traceCardHzContainer:hover .traceCardEdit {
  position: absolute;
  left: 10px;
  top: calc(50% - 25px);
  cursor: pointer;
  color: #2c5c69;
  display: block;
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  transition: opacity 0.3s linear;
}

.traceCardHzContainer:hover .traceCardEdit:hover {
  text-shadow: 0 0 3px #00e29f;
  transform: scale(1.3);
}

.traceCardHzContainer:hover .traceCardDelete {
  position: absolute;
  left: 10px;
  bottom: calc(50% - 25px);
  z-index: 10;
  cursor: pointer;
  color: red;
  opacity: 1;
  display: block;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  transition: opacity 0.3s linear;
  /* display: flex; */
}

.traceCardHzContainer:hover .traceCardDelete:hover {
  text-shadow: 0 0 3px #ff0000;
  transform: scale(1.3);
}


.dragIcon {
  height: 15px;
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  opacity: 0;
  transition: 0.3s;
  cursor: move;
}

.dragIconBottom {
  top: unset !important;
  bottom: 0 !important;
}

.dragIcon div {
  width: 5px;
  height: 5px;
  background: #bcbcbc;
  border-radius: 100%;
  margin: 4px;
}

.dragIconHz {
  height: 50px;
  width: 15px;
  position: absolute;
  left: 0;
  display: block;
  opacity: 0;
  transition: 0.3s;
  cursor: move;
  top: 50%;
  transform: translateY(-50%);
}

.dragIconHz div {
  width: 5px;
  height: 5px;
  background: #bcbcbc;
  border-radius: 100%;
  margin: 4px;
}

.dragIconHz:active,
.traceCardHzContainer:active,
.traceControls:active {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.dragIcon:active,
.traceCardHzContainer:active,
.traceControls:active {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.traceCardHzContainer:hover>.dragIconHz {
  opacity: 1;
}

.traceControls:hover>.dragIcon {
  opacity: 1;
}

.dropZone {
  outline: 4px dashed #dadada;
  animation: wiggle 0.3s infinite;
}

@keyframes wiggle {
  0% {
    transform: rotate(0.3deg);
  }

  50% {
    transform: rotate(-0.3deg);
  }

  100% {
    transform: rotate(0.3deg);
  }
}

@keyframes wiggleAndScale {
  0% {
    transform: rotate(0.3deg) scale(1.2);
  }

  50% {
    transform: rotate(-0.3deg) scale(1.2);
  }

  100% {
    transform: rotate(0.3deg) scale(1.2);
  }
}

.dim {
  opacity: 0.5;
  transform: scale(1.2);
}

.missingParamAlert {
  position: absolute;
  bottom: 145px;
  left: 0;
  width: 100%;
  height: fit-content;
  z-index: 1000;

}

.missingParamHzAlert {
  position: absolute;
  bottom: 0px;
  left: 120px;
  width: calc(100% - 140px);
  height: fit-content;
  z-index: 1000;

}

.refreshLine {
  background: #00e29f;
  position: absolute;
  width: 100%;
  top: 0px;
  left: -200;
  /* left: 0%; */
  height: 3px;
  z-index: 1000;
  /* background: transparent; */
  animation: animate 5s ease-in infinite;
}

.refreshLine::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: #00e29f;
  animation: animate 1s ease-in infinite;
}

@keyframes animate {

  20%,
  100% {
    transform: translate(100%, 0);
  }

  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }
}

.traceResultsTable {
  position: absolute;
  height: fit-content;
  left: 70px;
  bottom: 170px;
  background: white;
  min-width: 300px;
  z-index: 50;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.775);
  box-shadow: 0 0 3px 3px #00e29f;
}

.traceCloseButton {
  position: absolute;
  right: 1%;
  top: 1%;
  font-size: 16px;
  color: #ff5600;
  z-index: 51;
  padding: 5px;
  display: none;
}

.traceResultsTable:hover>.traceCloseButton {
  display: block;
  cursor: pointer
}

.paramManagerModal {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: fit-content;
  /* min-height: 500px; */
  z-index: 1000;
  padding: 15px;
  background: #3e3e3eE6;
  min-width: 400px;
}

.pageConfigModal {
  position: fixed;
  display: inline-block;
  overflow: hidden;
  z-index: 1000;
  top: 40%;
  width: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  max-width: 850px;
  height: fit-content;
  max-height: 800px;
  padding: 20px;
  background: #3e3e3eE6;
  flex-grow: 1
}